import { Box, Button, Container, Scene, Section } from '../components'
import shield from '../assets/images/shield.svg'
import android from '../assets/images/android.svg'
import ubuntu from '../assets/images/ubuntu.svg'
import kaliLinux from '../assets/images/kali-linux.svg'
import archLinux from '../assets/images/arch-linux.svg'
import { useActiveModal } from '../atoms/active-modal.atom'
import { EModals } from '../const'
import email from '../assets/images/email.svg'
import telegram from '../assets/images/telegram.svg'
import { Buffer } from 'buffer'

export const decode = (str: string) => {
  return Buffer.from(str, 'base64').toString('ascii')
}

const shiftLeft = (str: string, amount: number) => {
  return str.slice(amount) + str.slice(0, amount)
}

const HomePage = () => {
  const [, setOpenConnectModal] = useActiveModal(EModals.SEND_EMAIL)
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  const openModal = () => {
    setOpenConnectModal(true)
  }

  const handleTelegramClick = () => {
    window.open(decode(shiftLeft('cGxlX2JpdHM=aHR0cHM6Ly90Lm1lL211bHRp', 12)))
  }

  return (
    <>
      <Section id='home' className='!pb-0'>
        <Container className='flex flex-col justify-center items-center'>
          <h1 className='font-extrabold text-4xl sm:text-5xl text-center'>
            Roman Cybersecurity
          </h1>
        </Container>
      </Section>
      <Section className='!p-0'>
        <Container className='h-96'>
          <Scene />
        </Container>
      </Section>
      <Section id='about'>
        <Container>
          <h1 className='font-extrabold text-3xl text-center'>About</h1>
          <Box>
            <p className='text-white/70'>
              With over 20 years of hands-on experience in cybersecurity, I
              provide an array of services like penetration testing, compliance,
              and custom security solutions. Trust in my expertise and let's
              secure your digital universe.
            </p>
          </Box>
        </Container>
      </Section>
      <Section>
        <Container>
          <h1 className='font-extrabold text-3xl text-center'>
            Stats overview
          </h1>
          <Box className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-between'>
            <div className='flex flex-col gap-2 items-center justify-center border border-white/5 p-4'>
              <span className='text-center text-3xl text-acid-green'>500+</span>
              <span className='text-center text-white/70'>
                Sucessfull Cases
              </span>
            </div>
            <div className='flex flex-col gap-2 items-center justify-center border border-white/5 p-4'>
              <span className='text-center text-3xl text-acid-green'>
                $21,6M
              </span>
              <span className='text-center text-white/70'>
                Saved for my clients
              </span>
            </div>
            <div className='flex flex-col gap-2 items-center justify-center border border-white/5 p-4'>
              <span className='text-center text-3xl text-acid-green'>20+</span>
              <span className='text-center text-white/70'>
                Years of experience
              </span>
            </div>
            <div className='flex flex-col gap-2 items-center justify-center border border-white/5 p-4'>
              <span className='text-center text-3xl text-acid-green'>
                99,9%
              </span>
              <span className='text-center text-white/70'>
                Satisfaction rate
              </span>
            </div>
          </Box>
        </Container>
      </Section>
      <Section id='services'>
        <Container>
          <h1 className='font-extrabold text-3xl text-center'>Services</h1>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>Penetration Testing</h2>
              </div>
              <p className='text-white/70'>
                I uncover vulnerabilities with ethical hacking, providing
                actionable solutions to enhance security.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>
                  Incident Response & Forensics
                </h2>
              </div>
              <p className='text-white/70'>
                Responding in real time to breaches, I minimize damage and offer
                detailed analysis to prevent future incidents.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>
                  Compliance & Risk Management
                </h2>
              </div>
              <p className='text-white/70'>
                With a deep understanding of global regulations, I ensure your
                business exceeds industry standards.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>
                  Security Awareness Training & Workshops
                </h2>
              </div>
              <p className='text-white/70'>
                Benefit from my personalized training and workshops to empower
                your team against cyber threats.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>VIP Security Packages</h2>
              </div>
              <p className='text-white/70'>
                Trust in my tailored VIP packages for high-profile individuals
                or businesses requiring extra privacy and security.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>
                  Cloud Security Optimization
                </h2>
              </div>
              <p className='text-white/70'>
                I provide robust cloud security solutions, optimizing your
                architecture to safeguard critical data.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>Social Media Security</h2>
              </div>
              <p className='text-white/70'>
                I can help you to return control of your hacked social media
                accounts and secure them from future attacks.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>Cyber Investigation</h2>
              </div>
              <p className='text-white/70'>
                Identify and neutralize cybercriminals attacking your
                organization.
              </p>
            </Box>
          </div>
        </Container>
      </Section>
      <Section id='cases'>
        <Container className='flex flex-col gap-4'>
          <div className='grid grid-cols-2 sm:grid-cols-4 gap-4'>
            <img className='w-full opacity-20' src={android} alt='Android' />
            <img className='w-full opacity-20' src={ubuntu} alt='Ubuntu' />
            <img
              className='w-full opacity-20'
              src={kaliLinux}
              alt='Kali Linux'
            />
            <img
              className='w-full opacity-20'
              src={archLinux}
              alt='Arch Linux'
            />
          </div>
        </Container>
      </Section>
      <Section id='cases'>
        <Container className='flex flex-col gap-4'>
          <h1 className='font-extrabold text-3xl text-center'>Cases</h1>
          <Box className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>{'>'}</span>
                <h2 className='font-bold text-xl'>Twitter account recovery</h2>
              </div>
              <p className='text-white/70'>
                Miss A. is a famous influencer with over 200,000 followers on
                Twitter. Her account was hacked and she lost access to it. She
                contacted me and I was able to recover her account in less than
                24 hours.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>{'>'}</span>
                <h2 className='font-bold text-xl'>Bank security audit</h2>
              </div>
              <p className='text-white/70'>
                Bank X. is a large bank with over 1,000,000 customers. They
                contacted me to perform a security audit of their online
                banking. I was able to identify several vulnerabilities and
                provide actionable solutions to fix them.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>{'>'}</span>
                <h2 className='font-bold text-xl'>
                  Instagram blogger consulting
                </h2>
              </div>
              <p className='text-white/70'>
                Miss B. is a famous blogger with over 500,000 followers on
                Instagram. Her manager contacted me to secure her account and to
                tell the best practices to avoid hacks. I've helped her to
                secure her account and made a training for her and her team.
              </p>
            </Box>
            <Box className='flex flex-col gap-2'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>{'>'}</span>
                <h2 className='font-bold text-xl'>
                  Cybersecurity lecture for students
                </h2>
              </div>
              <p className='text-white/70'>
                I was invited to give a lecture about cybersecurity for
                students. I've talked about the importance of cybersecurity and
                how to protect yourself from cyber attacks.
              </p>
            </Box>
          </Box>
        </Container>
      </Section>
      <Section id='reach-me'>
        <Container>
          <h1 className='font-extrabold text-3xl text-center'>Reach me</h1>
          <Box className='sm:bg-shield sm:bg-contain flex flex-row gap-4'>
            <div className='flex flex-col gap-4 items-start'>
              <img
                src={shield}
                alt='Shield'
                className='image sm:hidden w-20 h-20'
              />
              {/* <div className='sm:hidden w-full h-24 bg-shield bg-contain bg-no-repeat bg-right-bottom' /> */}
              <div className='flex flex-row gap-2'>
                <span className='font-bold text-xl text-acid-green'>#</span>
                <h2 className='font-bold text-xl'>
                  Unlock Your Ultimate Cybersecurity Now!
                </h2>
              </div>
              <p className='text-white/70'>
                Are you gambling with your digital security? Don't wait until
                it's too late!
                <br /> Secure your digital world in just one click!
              </p>
              <Button className='w-full max-w-xs' onClick={openModal}>
                Send me a message
              </Button>
              <div className='flex flex-row gap-1 items-center hover:scale-110 cursor-pointer' onClick={handleTelegramClick} >
                <img src={telegram} alt='Telegram' className='w-8'/>
                <span>Telegram</span>
              </div>
            </div>
            <div className='hidden sm:flex items-center justify-center w-64'>
              {/* <img src={shield} alt='Shield' className='w-24' /> */}
            </div>
          </Box>
        </Container>
      </Section>
      <Section>
        <Container className='flex flex-col gap-4'>
          <p className='text-white/20 text-center'>
            <a
              href='https://sketchfab.com/3d-models/vintage-terminal-847c174b86e24d868d25217ca2297886'
              target='_blank'
              className='underline hover:text-white/30'
            >
              "Vintage Terminal"
            </a>{' '}
            by{' '}
            <a
              href='https://sketchfab.com/NoMoreFeelings'
              target='_blank'
              className='underline hover:text-white/30'
            >
              NoMoreFeelings
            </a>{' '}
            is licensed under{' '}
            <a
              href='http://creativecommons.org/licenses/by/4.0/'
              target='_blank'
              className='underline hover:text-white/30'
            >
              CC BY 4.0
            </a>{' '}
            and was modified by Roman Cybersecurity.
          </p>
          <p className='text-white/20 text-center'>
            © 2023 Roman Cybersecurity. All rights reserved.
          </p>
        </Container>
      </Section>
    </>
  )
}

export default HomePage
