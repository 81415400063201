import React from 'react';
import { motion } from 'framer-motion';

interface IBoxProps {
  className?: string;
  children?: React.ReactNode;
  id?: string;
}

const Box = (props: IBoxProps) => {
  const { className, children, id } = props;
  const classes = className ? className : '';
  return (
    <div
       id={id}
      // initial={{ opacity: 0, y: 50 }}
      // animate={{ opacity: 1, y: 0 }}
      // transition={{ delay: 0.1 }}
      className={`p-6 bg-white/5 bg-box border border-white/5 bg-no-repeat bg-right-bottom bg-opa bg-sm ${classes}`}
    >
      {children}
    </div>
  );
};

export { Box };
