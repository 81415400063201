import { Canvas } from '@react-three/fiber'
import React, { Suspense } from 'react'
import { VintageTerminalModel } from './vintage-terminal.component'
import { Environment, OrbitControls } from '@react-three/drei'

const Scene = () => {
  return (
    <Canvas camera={{ fov: 60 }}>
      <Suspense fallback={null}>
        <VintageTerminalModel />
      </Suspense>
      <Environment preset='warehouse' />
      <OrbitControls
        enableZoom={false}
        maxAzimuthAngle={Math.PI / 4}
        minAzimuthAngle={-Math.PI / 4}
        maxPolarAngle={Math.PI / 1.8}
        minPolarAngle={Math.PI / 3}
      />
    </Canvas>
  )
}

export { Scene };
