import React from 'react';
import { motion } from 'framer-motion';

interface IContainerProps {
  className?: string;
  children?: React.ReactNode;
  id?: string;
}

const Container = (props: IContainerProps) => {
  const { className, children, id } = props;
  const classes = className ? className : '';
  return (
    <div
      className={`flex flex-col gap-4 w-full max-w-4xl ${classes}`}
    >
       {children}
    </div>
  );
};

export { Container };