import React from 'react';

// extend children props
interface ISectionProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
}

const Section = (props: ISectionProps) => {
  const { className, children, ...rest } = props;
  const classes = className ? className : '';

  return (
    <section
      className={`flex flex-col gap-10 items-center w-full py-6 px-5 md:py-12 md:px-10 ${classes}`}
      {...rest}
    >
      {children}
    </section>
  );
};


export { Section };