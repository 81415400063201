import React from 'react';

interface IButtonProps {
  className?: string;
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
}

const Button = (props: IButtonProps) => {
  const { className, children, variant = 'primary', onClick } = props;
  const classes = className ? className : '';

  const variants = {
    primary: `relative text-acid-green underline hover:no-underline hover:before:content-['>'] before:absolute before:-translate-x-full hover:after:content-['<'] after:absolute border border-acid-green`,
    secondary: `relative text-white underline hover:no-underline hover:before:content-['>'] before:absolute before:-translate-x-full hover:after:content-['<'] after:absolute border border-white`,
  };

  return (
    <button
      className={`px-6 py-3 ${variants[variant]} ${classes}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export { Button };