import React, { useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  useActiveModal,
  useUpdateOpenedModal,
} from '../atoms/active-modal.atom'
import { EModals } from '../const'
import { Box } from '../components/box.component'
import { Button } from '../components'
import { toast } from 'react-toastify'
import axios from 'axios'
import { config } from 'process'
import { decode } from '../pages/home.page'

export const SendEmailModal = () => {
  const [isOpen] = useActiveModal(EModals.SEND_EMAIL)
  const setOpen = useUpdateOpenedModal()

  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')

  const sendNotification = async (data: { email: string, message: string }) => {
    const {email: _email, message: _message} = data;
    const options = {
      method: 'POST',
      url: decode('aHR0cHM6Ly9tdWx0aXBsZWJpdHMuY29tL2FwaS9zZW5kTm90aWZpY2F0aW9u'),
      headers: {'Content-Type': 'application/json'},
      data: {email: _email, message: _message}
    };
    const response = await axios.request(options);

    return response
  }

  const closeModal = () => {
    setOpen(EModals.SEND_EMAIL, false)
  }

  const handleSendEmail = () => {
    if (email === '' || message === '') {
      toast.error('Please fill all the fields')
      return
    } else {
      sendNotification({email, message})
      toast.success('Email sent!')
      closeModal()
    }
  }

  return (
    <Transition.Root appear show={isOpen} as={React.Fragment}>
      <Dialog
        onClose={closeModal}
        className='fixed inset-0 z-10 overflow-y-auto'
      >
        <Transition.Child
          as={React.Fragment}
          enter='ease-out duration-100'
          enterFrom='opacity-0'
          enterTo='opacity-50'
          leave='ease-in duration-100'
          leaveFrom='opacity-50'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay className='fixed inset-0 bg-black' />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter='ease-out duration-200'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='flex items-center justify-center min-h-screen p-8'>
            <Box
              className={
                'w-full !bg-black border-white/30 max-w-2xl z-20 flex flex-col gap-6'
              }
            >
              <div className='flex flex-row justify-between items-center'>
                <Dialog.Title as='h1' className={'font-bold text-xl'}>
                  Send me an email
                </Dialog.Title>
                <span
                  className={
                    'cursor-pointer text-2xl transition-transform hover:scale-110'
                  }
                  onClick={closeModal}
                >
                  [x]
                </span>
              </div>
              <Dialog.Description as={'p'} className={'text-white/70'}>
                Tell me how can I help you.
              </Dialog.Description>
              <input
                className={
                  'bg-black border border-acid-green p-4 text-white/70 invalid:border-red-500'
                }
                placeholder={'Your email'}
                type='email'
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <textarea
                className={
                  'bg-black border border-acid-green p-4 text-white/70'
                }
                placeholder={'Your message'}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <Button onClick={handleSendEmail}>Send email</Button>
            </Box>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}
