import React from 'react';
import { SendEmailModal } from './send-email.modal';

export const Modals = () => {
  return (
    <>
      <SendEmailModal/>
    </>
  );
};
