import React from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import { Group } from 'three'

const VintageTerminalModel = (props: JSX.IntrinsicElements['group']) => {
  const { nodes, materials } = useGLTF('/3d-models/vintage-terminal.glb') as any
  // animate group rotation
  const group = useRef<Group>(null)
  useFrame((_state, _delta) => {
    group.current?.rotation?.set(
      // group.current.rotation.x,
      // group.current.rotation.y + 0.005,
      // group.current.rotation.z,
      // use sin and cos to create a wave effect
      Math.sin(_state.clock.getElapsedTime()) / 10,
      Math.cos(_state.clock.getElapsedTime()) / 10,
      0
    )
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        rotation={[(-Math.PI / 2) + 0.3, 0, 0]}
        scale={0.958}
        position={[0, 0.5, 0]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_2.geometry}
          material={materials['Material.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3.geometry}
          material={materials['Material.002']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_4.geometry}
          material={materials['Material.003']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_5.geometry}
          material={materials['Material.004']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_6.geometry}
          material={materials['None.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_7.geometry}
          material={materials['None.003']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/3d-models/vintage-terminal.glb')

export { VintageTerminalModel }
