import React from 'react'
import { Section } from './section.component'
import { Container } from './container.component'
import { Bars3Icon } from '@heroicons/react/24/solid'
import { motion } from 'framer-motion'
import whiteLogo from '../assets/images/logo-white.svg'

const MENU = [
  {
    name: 'About',
    href: '#about',
  },
  {
    name: 'Services',
    href: '#services',
  },
  {
    name: 'Cases',
    href: '#cases',
  },
  {
    name: 'Reach me',
    href: '#reach-me',
  },
]

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false)

  const variants = {
    open: { height: 'auto', opacity: 1 },
    closed: { height: 0, opacity: 0 },
  }

  return (
    <Section>
      <Container className='flex flex-col gap-4 justify-center items-center'>
        <div className='w-full flex flex-row justify-between items-center'>
          <a
            href='#home'
            className='w-6 h-6 flex flex-row items-center gap-2 hover:scale-110 transition-transform'
          >
            <img src={whiteLogo} alt='Logo' className='w-6 h-6' />
          </a>
          <Bars3Icon
            className={`w-6 h-6 sm:hidden cursor-pointer`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
          <nav
            className={`hidden flex-row items-center sm:flex gap-8 [&>a]:text-acid-green`}
          >
            {MENU.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={`relative hover:before:content-['>'] before:absolute before:-translate-x-full`}
              >
                {item.name}
              </a>
            ))}
          </nav>
        </div>
        <motion.nav
          variants={variants}
          initial='closed'
          animate={isMenuOpen ? 'open' : 'closed'}
          className={`w-full flex flex-col sm:hidden gap-2 [&>a]:text-acid-green overflow-hidden`}
        >
          {MENU.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={`w-full py-4 px-8 bg-acid-green/5 rounded-sm relative hover:before:content-['>'] before:absolute before:-translate-x-full`}
            >
              {item.name}
            </a>
          ))}
        </motion.nav>
      </Container>
    </Section>
  )
}

export { Header }
