import { atom, useRecoilState, useRecoilCallback } from 'recoil';
import { TActiveModals } from '../types/active-modals.type';
import { EModals, EAtoms } from '../const';

const activeModals: TActiveModals = {
  [EModals.SEND_EMAIL]: false,
};

export const activeModalAtom = atom({
  key: EAtoms.ACTIVE_MODALS,
  default: activeModals,
});

export const useActiveModal = (modal: EModals): [boolean, (value: boolean) => void] => {
  const [activeModals, setActiveModals] = useRecoilState(activeModalAtom);

  return [activeModals[modal], (value: boolean) => setActiveModals({ ...activeModals, [modal]: value })];
}

export const useUpdateOpenedModal = () => {
  return useRecoilCallback(({ set }) => (modal: EModals, state: boolean) => {
    set(activeModalAtom, (currentModals) => ({
      ...currentModals,
      [modal]: state,
    }));
  });
}
