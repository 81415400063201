import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import HomePage from './pages/home.page'
import { Header } from './components'
import { RecoilRoot } from 'recoil';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Fonts
import './assets/fonts/AnekLatin-Regular.ttf'
import './assets/fonts/AnekLatin_Expanded-Bold.ttf'
import './assets/fonts/Roboto-Regular.ttf'
import { Modals } from './modals'

window.Buffer = window.Buffer || require("buffer").Buffer; 

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<HomePage />} />
        </Routes>
        <Modals />
        <ToastContainer theme='dark' />
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
